





































































import { Component } from 'vue-property-decorator'
import Loading from '@/components/Loading.vue'
import Header from '@/components/Header.vue'
import Alert from '@/components/Alert.vue'
import Button from '@/components/Button.vue'
import Content from '@/components/Content.vue'
import OrderSummary from '@/components/orders/OrderSummary.vue'
import OrderItemList from '@/components/orders/OrderItemList.vue'
import OrderNumber from '@/components/orders/OrderNumber.vue'
import Tile from '@/components/Tile.vue'
import { Identity } from '@/models/Identity'
import { UserOrder } from '@/models/UserOrder'
import { firestore } from 'firebase/app'
import { UserOrderConverter } from '@/models/converters/UserOrderConverter'
import { UserOrderItem } from '@/models/UserOrderItem'
import { UserOrderItemConverter } from '@/models/converters/UserOrderItemConverter'
import { mixins } from 'vue-class-component'
import UserMixin from '@/mixins/UserMixin.vue'

@Component({
  components: {
    Loading,
    Header,
    Alert,
    Button,
    Content,
    OrderSummary,
    OrderItemList,
    OrderNumber,
    Tile
  }
})
export default class OrderDetail extends mixins(UserMixin) {
  loading: boolean = false
  confirmation: boolean = false

  order: UserOrder = null
  orderItems: UserOrderItem[] = []
  subscriber: () => void

  get identity(): Identity {
    return this.mainStore.identity
  }

  async created() {
    this.loading = true

    this.confirmation = this.$route.query?.type === 'confirmation'

    const orderId = this.$route.params.orderId

    this.subscriber = firestore()
      .collection('users')
      .doc(this.mainStore.user.id)
      .collection('orders')
      .doc(orderId)
      .withConverter(new UserOrderConverter())
      .onSnapshot(async (order) => {
        this.order = order.data()

        this.orderItems = (
          await this.order.reference
            .collection('items')
            .withConverter(new UserOrderItemConverter())
            .get()
        ).docs.map((doc) => doc.data())

        for (const orderItem of this.orderItems) {
          await orderItem.populate()
        }

        this.loading = false
      })
  }

  destroyed() {
    if (this.subscriber) {
      this.subscriber()
    }
  }
}
